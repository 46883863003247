<template>
    <v-container fill-height class="my-3">
        <v-layout column>
            <AdBTWR v-if="!user.subscribed"/>

            <v-row justify="center">
                <v-col cols="12" xl="10">
                    <License
                        :key="$route.params.callsign"
                        :user="user"
                        :callsign="$route.params.callsign"
                        :showMap="true"
                        :showHistory="true"
                    />
                </v-col>
            </v-row>
        </v-layout>
    </v-container>
</template>

<script>
    import License from '@/components/License.vue'
    import AdBTWR from '@/components/ads/BTWR.vue'
    import {useTitle} from '@vueuse/core'

    export default {
        props: ['user'],

        data: () => ({}),

        components: {
            License,
            AdBTWR
        },

        mounted() {
            let callsign = this.$route.params.callsign;
            if (this.$_.isString(callsign)) callsign = callsign.toUpperCase();
            useTitle(`GMRS License: ${callsign} - myGMRS.com`);
        }
    }
</script>
